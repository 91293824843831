<template>
  <section class="checkout-page d-flex flex-row justify-between ">
    <div class="btn-back mobile d-flex" @click="goToShop()">
      <img src="@/assets/icons/Arrow-left.svg" alt="">
      <h6 class="title">Back</h6>
    </div>
    <div class="information">
      <div class="btn-back desktop d-flex" @click="goToShop()">
        <img src="@/assets/icons/Arrow-left.svg" alt="">
        <h6 class="title">Back</h6>
      </div>
      <div class="payment-steps d-flex flex-row align-center">
        <div class="step d-flex flex-row align-center">
          <div class="number d-flex flex-row align-center justify-center"><span>1</span></div>
          <h5 class="title">Delivery Info</h5>
        </div>
        <div class="line"></div>
        <div class="step d-flex flex-row align-center">
          <div class="number d-flex flex-row align-center justify-center">2</div>
          <h5 class="title">Personal Info</h5>
        </div>
        <div class="line"></div>
        <div class="step d-flex flex-row align-center">
          <div class="number d-flex flex-row align-center justify-center">3</div>
          <h5 class="title">Payment</h5>
        </div>
      </div>
      <form @submit.prevent>
        <DatePicker
            v-model="$v.deliveryInfo.date.$model"  color="indigo"
            :locale="{ firstDayOfWeek: 2, masks: { weekdays: 'WWW',title:'MMMM' } }"
            is-dark
            >
          <template v-slot="{ inputValue, inputEvents }">
            <div id="datePickContainer" :class="{'error':$v.deliveryInfo.date.$error}">
              <input
                  id="datePickInput"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="Date"
              />
              <img src="@/assets/icons/Calendar.svg" alt="">
            </div>
          </template>
        </DatePicker>
        <div class="input-containers top d-flex">
          <input class="fname"
                 :class="{'error':$v.deliveryInfo.fname.$error}"
                 v-model="$v.deliveryInfo.fname.$model"
                 type="text"
                 placeholder="First name*">
          <input class="lname"
                 type="text"
                 placeholder="Last name*"
                 :class="{'error':$v.deliveryInfo.lname.$error}"
                 v-model="$v.deliveryInfo.lname.$model">
        </div>
        <div class="checkbox-container">
          <div class="d-flex flex-row justify-between align-center">
            <div class="custom-checkbox-container">
              <input class="custom-checkbox" v-model="deliveryInfo.typeAddress" type="radio" :value="0">
              <label class="custom-label">Home address</label>
            </div>
            <div class="custom-checkbox-container d-flex">
              <input class="custom-checkbox" v-model="deliveryInfo.typeAddress" type="radio" :value="1">
              <label class="custom-label">Business address</label>
            </div>
          </div>
        </div>
        <div class="input-containers d-flex">
          <input class="street"
                 type="text"
                 placeholder="Street*"
                 :class="{'error':$v.deliveryInfo.street.$error}"
                 v-model="$v.deliveryInfo.street.$model">
          <input class="house"
                 type="text"
                 placeholder="House №*"
                 :class="{'error':$v.deliveryInfo.house.$error}"
                 v-model="$v.deliveryInfo.house.$model">
        </div>
        <div class="input-containers d-flex">
          <input class="addition-info"
                 type="text"
                 placeholder="Additional info (optional)"
                 :class="{'error':$v.deliveryInfo.additional.$error}"
                 v-model="$v.deliveryInfo.additional.$model">
        </div>
        <div class="input-containers d-flex">
          <input class="post-code"
                 type="text"
                 placeholder="Post Code*"
                 :class="{'error':$v.deliveryInfo.postCode.$error}"
                 v-model="$v.deliveryInfo.postCode.$model">
          <input class="city"
                 type="text"
                 placeholder="City*"
                 :class="{'error':$v.deliveryInfo.city.$error}"
                 v-model="$v.deliveryInfo.city.$model">
        </div>
        <div class="input-containers d-flex flex-column ">
          <textarea class="special"
                    type="text"
                    placeholder="Special Instructions (optional)"
                    :class="{'error':$v.deliveryInfo.special.$error}"
                    v-model="$v.deliveryInfo.special.$model"
                    maxlength="120">
          </textarea>
          <p class="max-char">Max 120 characters</p>
        </div>
        <div class="next-step d-flex flex-row justify-between align-center">
          <div class="btn-continue-shop" @click="continueShopping">
            Continue shopping
          </div>
          <div class="btn-next-step" @click="nextPage">
            <h6>Next step</h6>
          </div>
        </div>
      </form>
    </div>
    <CheckoutBasket />
  </section>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import {validationMixin} from 'vuelidate';
import {required,maxLength} from 'vuelidate/lib/validators';
export default {
  name: "Checkout",
  mixins:[validationMixin],
  data(){
    return{
      value:null,
      deliveryInfo:{
        fname:'',
        lname:'',
        street:'',
        house:'',
        additional:'',
        postCode:'',
        city:'',
        special:'',
        typeAddress:0,
        date:''
      },
      submitStatus: null
    }
  },
  validations:{
    deliveryInfo:{
      fname:{
        required
      },
      lname:{
        required
      },
      street:{
        required
      },
      house:{
        required
      },
      additional:{
        required
      },
      postCode:{
        required
      },
      city:{
        required
      },
      special:{
        maxLength:maxLength(120)
      },
      date:{
        required
      }
    },
  },
  computed:{
    deliveryData(){
      const deliveryData = this.$store.getters['receiverDeliveryInfo'];
      if(deliveryData !== null) this.deliveryInfo = deliveryData;
      return 1
    }
  },
  methods:{
    nextPage(){
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.$store.dispatch('setDeliveryInfo',this.deliveryInfo)
        this.$router.push('/checkoutPersonalInfo')
      }
    },
    goToShop(){
      this.$router.push('/')
    },
    continueShopping(){
      this.$router.push('/shop')
    }
  },
  components:{
    CheckoutBasket:()=> import('./CheckoutBasket/CheckoutBasket'),
    DatePicker,
  },
  mounted() {
    this.deliveryData
  }
}
</script>

<style scoped lang="scss">
@import "Checkout";
#datePickInput{
  font-size: 16px;
  outline: none;
  width: 100%;
  background-color: inherit;
  &::placeholder{
  color: #FFFFFF;
  }
}
#datePickContainer{
  display:flex;
  background-color:#262627;
  border-radius:12px;
  justify-content:space-between;
  padding:16px;
  cursor:pointer;
  &.error{
    border: 1px solid red;
  }
}
</style>